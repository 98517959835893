import React from "react";
import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles"
import SplitLayout from "../components/splitlayout"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Box } from "@material-ui/core"
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root:{
    width:'80%',
  },
  formElements:{
    display: 'flex',
    flexDirection:'column',
    width:'70%',
    alignContent:'flex-end',
  },
}));

export default function Contact() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "sunset.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);



  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('not_sent');


  const onSend = (event) => {
    console.log("hello");
    setStatus('sending');

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    axios.post('https://6ki99u4kx5.execute-api.us-west-2.amazonaws.com/live', {
      name: name,
      email: email,
      message: message
    }, axiosConfig)
    .then(function (response) {
      setStatus('success');
      console.log('success')
      console.log(response);
    })
    .catch(function (error) {
      setStatus('error');
      console.log('error')
      console.log(error);
    });
  };

  const formComponents = (
    <div className={classes.formElements}>
      <Typography variant="h4" align="center" gutterBottom="true">
        Contact
      </Typography>
      <TextField
        id="name"
        label="Name"
        fullWidth
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <TextField
        id="email"
        label="E-Mail"
        fullWidth
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        id="message"
        label="Message"
        multiline
        fullWidth
        rows={8}
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Box m={2}>
          <Button variant="outlined" onClick={onSend} disabled={status!=='not_sent'}>Send</Button>
        </Box>
      </Box>
    </div>
  );

  const sendingComponents  = (
    <div className={classes.formElements}>
      <Typography variant="h4" align="center" gutterBottom="true">
        Contact
      </Typography>
      <Typography variant="body1" align="center" gutterBottom="true">
        Sending message...
      </Typography>
    </div>
  );

  const successComponents  = (
    <div className={classes.formElements}>
      <Typography variant="h4" align="center" gutterBottom="true">
        Contact
      </Typography>
      <Typography variant="body1" align="center" gutterBottom="true">
        Thank you for reaching out!
      </Typography>
    </div>
  );

  const failureComponents  = (
    <div className={classes.formElements}>
      <Typography variant="h4" align="center" gutterBottom="true">
        Contact
      </Typography>
      <Typography variant="body1" align="center" gutterBottom="true">
        Sorry! We encountered a technical problem while sending the message.
        Please try again later.
      </Typography>
    </div>
  );

  let components;
  if (status === 'not_sent'){
    components = formComponents;
  }else if (status === 'sending'){
    components = sendingComponents;
  }else if (status === 'success'){
    components = successComponents;
  }else {
    components = failureComponents;
  }

  return(
    <SplitLayout image={data.image.childImageSharp.fluid}>
      {components}
    </SplitLayout>
  );
}

